<template>
  <page-loader :loading="isFetchingList">
    <b-overlay :show="blockLoading">
      <b-card no-body>
        <confirm-modal ref="simulationConfirmModal" />

        <div class="p-2 d-flex justify-content-between">
          <b-card-title class="m-0">{{
            T("Web.SimluationsPage.Title", "Simulations")
          }}</b-card-title>
          <b-dropdown
            class="float-right"
            size="sm"
            variant="secondary"
            no-caret
            right
          >
            <template slot="button-content">
              {{ T("Web.Generic.New", "New") }} <feather-icon icon="PlusIcon" />
            </template>
            <b-dropdown-item
              @click="handleCreateSimulationOfType('WasteHandlingSimulation')"
            >
              <feather-icon icon="BarChart2Icon" />
              {{
                T(
                  "Web.Generic.Simulations.Types.WasteHandlingSimulation",
                  "Waste handling simulation"
                )
              }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                handleCreateSimulationOfType('WasteDistributionSimulation')
              "
            >
              <feather-icon icon="PieChartIcon" />
              {{
                T(
                  "Web.Generic.Simulations.Types.WasteDistributionSimulation",
                  "Waste distribution simulation"
                )
              }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div>
          <b-table
            :fields="tableFields"
            :items="simulations"
            show-empty
            :empty-text="
              T(
                'Web.SimluationsPage.CreateNewSimulationToGetStarted',
                'Create new simulation to get started'
              )
            "
          >
            <template #cell(type)="data">
              <b-badge variant="primary">{{
                T(`Web.Generic.Simulations.Types.${data.value}`)
              }}</b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-button
                  class="float-right"
                  variant="flat-danger"
                  size="sm"
                  @click="removeSimulation(data.item.id)"
                >
                  {{ T("Web.Generic.Delete", "Delete") }}
                </b-button>
                <b-button
                  variant="flat-secondary"
                  class="float-right"
                  size="sm"
                  :to="
                    getSimulationEditRouteFromType(data.item.type, data.item.id)
                  "
                >
                  {{ T("Web.Generic.ShowEdit", "Show/edit") }}
                </b-button>
              </span>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-overlay>
  </page-loader>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { uuid } from "vue-uuid";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BButton,
    BTable,
    BCard,
    BOverlay,
    ConfirmModal,
    PageLoader,
    BBadge,
  },
  data() {
    return {
      blockLoading: false,
      tableFields: [
        {
          key: "title",
          label: this.T("Web.Generic.Title", "Title"),
          sortable: true,
        },
        {
          key: "type",
          label: this.T("Web.Generic.Type", "Type"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  created() {
    this.fetchList();
  },
  computed: {
    ...mapGetters({
      isFetchingList: "economics/simulations/isFetchingList",
      simulations: "economics/simulations/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchList: "economics/simulations/fetchList",
      createSimulation: "economics/simulations/create",
      deleteSimulation: "economics/simulations/delete",
    }),
    async removeSimulation(id) {
      if (!(await this.$refs.simulationConfirmModal.confirm())) return;
      this.blockLoading = true;
      await this.deleteSimulation({ resourceId: id });
      this.blockLoading = false;
    },
    getSimulationEditRouteFromType(type, id) {
      const routeName = `economics.simulations.${type
        .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
        .substring(1)}.edit`;
      return { name: routeName, params: { id: id } };
    },

    async handleCreateSimulationOfType(type) {
      try {
        this.blockLoading = true;
        const resourceId = uuid.v4();
        await this.createSimulation({
          data: {
            type: type,
            title: `${this.T("Web.Generic.Untitled", "Untitled")} ${this.T(
              `Web.Generic.Simulations.Types.${type}`
            ).toLowerCase()}`,
          },
          resourceId: resourceId,
        });
        this.$router.push(
          this.getSimulationEditRouteFromType(type, resourceId)
        );
      } finally {
        this.blockLoading = false;
      }
    },
  },
};
</script>